import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SearchTable from './SearchTable';

ReactDOM.render(
  <React.StrictMode>
      <SearchTable />
  </React.StrictMode>,
  document.getElementById("root")
);

