import React from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
import dataRaw from "./data";

import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import SearchRounded from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Box } from "@mui/material";
import TablePaginationActions from "./components/TablePaginationActions";
import DigiNavBar from "./components/DigiNavBar";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import myDataLogo from "./assets/support.mydata.logo.white.tiny.png";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const accentMap = {
  ά: "α",
  έ: "ε",
  ό: "ο",
  ώ: "ω",
  ύ: "υ",
  ϋ: "υ",
  ΰ: "υ",
  ί: "ι",
  ϊ: "ι",
  ΐ: "ι",
  ή: "η",
  ς: "σ",
};

const normalize = function (term) {
  var ret = "";
  term = term.toLowerCase();
  for (var i = 0; i < term.length; i++) {
    ret += accentMap[term.charAt(i)] || term.charAt(i);
  }
  return ret.toUpperCase();
};

const indexedData = dataRaw.map((item, index) => {
  return {
    ...item,
    SearchIndex:
      normalize(item.Name) +
      " " +
      normalize(item.Katigoria) +
      " " +
      normalize(item.XaraktirismosE3),
  };
});

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(normalize(value) || undefined);
  }, 200);

  return (
    <Box sx={{ px: 2 }}>
      <TextField
        sx={{ maxWidth: "100%" }}
        fullWidth
        id="outlined-basic"
        label="Αναζήτηση..."
        variant="outlined"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} παραστατικά...`}
        // style={{
        //   fontSize: "1.1rem",
        //   border: "0",
        // }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Box sx={{ pt: 1 }}>
      <TextField
        sx={{ maxWidth: "100%", p: 0 }}
        fullWidth
        label="Αναζήτηση..."
        variant="outlined"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} παραστατικά...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  //return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  return matchSorter(rows, filterValue, { keys: ["values.SearchIndex"] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const defaultPropGetter = () => ({});
// Our table component
function Table({ columns, data, getCellProps = defaultPropGetter }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        console.log(rows);
        console.log(id);
        console.log(filterValue);
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: ["SearchIndex", "Typos"],
      },
      globalFilter: "fuzzyText",
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows; //rows.slice(0, 10)
  //console.log(page);

  return (
    <Box
      sx={{
        pt: 1,
      }}
    >
      <MaUTable {...getTableProps()}>
        <TableHead>
          <TableRow>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </TableRow>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter((col) => col.depth > 0)
                .map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      size="medium"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "x-large",
                      }}
                    >
                      {column.render("Header")}
                      {/* Render the columns filter UI */}
                      {
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      }
                      {/* <Box sx={{ pt: 1 }}>
                        {column.canFilter ? column.render("Filter") : null}
                      </Box> */}
                    </TableCell>
                  );
                })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps([getCellProps(cell)])}
                      sx={{ p: 1 }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              // SelectProps={{
              //   inputProps: { "aria-label": "page number" },
              //   //native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Παραστατικά ανά σελίδα"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} από ${count}`
              }
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </Box>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function SearchTable() {
  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Name',
      //   columns: [
      //     {
      //       Header: 'First Name',
      //       accessor: 'firstName',
      //     },
      //     {
      //       Header: 'Last Name',
      //       accessor: 'lastName',
      //       // Use our custom `fuzzyText` filter on this column
      //       filter: 'fuzzyText',
      //     },
      //   ],
      // },
      {
        Header: "Info",
        columns: [
          {
            Header: "Παραστατικό",
            accessor: "Name",
          },
          {
            Header: "Κατηγορία χαρακτηρισμού",
            accessor: "Katigoria",
          },
          {
            Header: "Τύπος χαρακτηρισμού (Ε3)",
            accessor: "XaraktirismosE3",
          },
          {
            Header: "Typos",
            accessor: "Typos",
          },
          {
            Header: "SearchIndex",
            accessor: "SearchIndex",
          },
        ],
      },
    ],
    []
  );

  const indexDataMemo = React.useMemo(() => indexedData, []);

  return (
    <>
      <DigiNavBar logoUrl={myDataLogo} />
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 4,
          pb: 4,
        }}
      >
        <Typography variant="h5" align="center" color="text.secondary">
          Υπολογιστής παραστατικών myDATA (βρείτε όλους τους επιτρεπόμενους
          χαρακτηρισμούς (κατηγορίες και Ε3)) ως εκδότης και ως λήπτης
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          pt={4}
          pb={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Toolbar />
          <CssBaseline />
          <Grid item xs={10}>
            <Table
              columns={columns}
              data={indexDataMemo}
              getCellProps={(cellInfo) => {
                //console.log(cellInfo);
                return {
                  style: {
                    backgroundColor:
                      cellInfo.row.values.Typos === 1 ? "aliceblue" : "default",
                  },
                };
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SearchTable;
